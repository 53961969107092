import React, { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getShirts, shirtPatterns } from "../../Data";
import ShirtDetails from "../../Assets/Svg1.svg";
import { Modal } from "react-bootstrap";
import AddModal from "./AddModal";
import EditModal from "./EditModal";
import { useDispatch, useSelector } from "react-redux";
import PatternFilter from "./PatternFilter";
import AddBtn from "./AddBtn";
import { deletePattern, getPattern } from "../../Redux/Action/pattern";
import { DeleteOutlined } from "@ant-design/icons";
import DeleteModal from "../Modals/DeleteModal";
import { message } from "antd";
import { clearError } from "../../Redux/Reducer/patternReducer";
import Pagination from "../Pagination/Pagination";
import Loader from "../Loader/Loader";

const Chesst = ({ place, showEdit, setShowEdit }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [ShirtType, setShirtType] = useState();
  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(1);
  const [editData, setEditData] = useState(null);
  const { data, loading } = useSelector((state) => state.pattern);

  const [modalOpen, setModalOpen] = useState(false);
  const [ids, setId] = useState();
  const { success, error, deleteSuccess, deleteError } = useSelector(
    (state) => state.pattern
  );

  const handleModalOpen = (id) => {
    setId(id);
    setModalOpen(!modalOpen);
  };
  const handlePageChange = (page, pageSize) => {
    setPage(page);
    setPageSize(pageSize);
  };
  const handleDelete = async (id) => {
    dispatch(deletePattern(id));
  };
  useEffect(() => {
    setShirtType(id);
  }, []);

  const handleCloseEdit = () => setShowEdit(false);
  const handleShowEdit = (pattern) => {
    setEditData(pattern);
    setShowEdit(true);
  };

  const handleClose = () => {
    dispatch(getPattern({ place, ShirtType }));

    setShow(false);
  };

  const handleShow = () => setShow(true);
  const { id } = useParams();
  const shirt = getShirts.find((shirt) => shirt.id === id);

  useEffect(() => {
    if (error) {
      message.error(error);
      dispatch(clearError());
    }
    if (success) {
      message.success(success);
      dispatch(clearError());
      handleClose();
    }
  }, [error, success]);

  useEffect(() => {
    if (deleteError) {
      message.error(deleteError);
      dispatch(clearError());
    }
    if (deleteSuccess) {
      message.success(deleteSuccess);
      dispatch(clearError());
      handleClose();
    }
  }, [deleteError, deleteSuccess]);

  return (
    <Fragment>
      <div className="container-fluid">
        <div className="row topMargin">
          <AddBtn handleClose={handleClose} handleShow={handleShow} />
          <div className="col-sm-3 svgHeader">
            <img src={shirt?.source} alt={shirt?.name} />
          </div>
        </div>

        <PatternFilter
          place={place}
          page={page}
          pageSize={pageSize}
          id={id}
          setShowEdit={setShowEdit}
          showEdit={showEdit}
        />
        {loading ? (
          <div className="lottie-dash">
            <Loader />
          </div>
        ) : (
          <>
            <div className="row listing">
              <div className="col-sm-12">
                <table>
                  <thead>
                    <tr>
                      <th>S.No</th>
                      <th>Name</th>
                      <th>Status</th>
                      <th>Category</th>
                      <th>Image</th>
                      {/* <th>Action</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {data?.items?.map((item, index) => (
                      <tr key={item.itemId}>
                        <td
                          className="assdd"
                          onClick={() => handleShowEdit(item)}
                        >
                          {index + 1 + ")"}
                        </td>
                        <td
                          className="assdd"
                          onClick={() => handleShowEdit(item)}
                        >
                          {item?.Name}
                        </td>
                        <td
                          className="assdd"
                          onClick={() => handleShowEdit(item)}
                        >
                          {item?.Status ? "Active" : "InActive"}
                        </td>
                        <td
                          className="assdd"
                          onClick={() => handleShowEdit(item)}
                        >
                          {item?.PatternPlace}
                        </td>
                        <td
                          className="assdd"
                          onClick={() => handleShowEdit(item)}
                        >
                          <div
                            style={{ width: "50px" }}
                            dangerouslySetInnerHTML={{ __html: item?.SvgPath }}
                          />
                        </td>
                        {/* <td>
                          <DeleteOutlined
                            className="assdd"
                            onClick={() => handleModalOpen(item?._id)}
                          />
                        </td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <Pagination
              TotalData={data?.totalCount}
              handlePageChange={handlePageChange}
              currentPage={page}
            />
          </>
        )}
      </div>
      <Modal show={show} onHide={handleClose} className="AddModal">
        <AddModal
          designName={shirt && shirt?.name}
          handleClose={handleClose}
          place={place}
        />
      </Modal>
      <Modal show={showEdit} onHide={handleCloseEdit} className="AddModal">
        <EditModal
          patternData={editData}
          patternType={place}
          designName={shirt && shirt?.name}
          place={place}
          handleClose={handleCloseEdit}
        />
      </Modal>

      <DeleteModal
        handleDelete={handleDelete}
        id={ids}
        setModalOpen={setModalOpen}
        modalOpen={modalOpen}
      />
    </Fragment>
  );
};

export default Chesst;
